jQuery( document ).ready(function($) {

  // mobile menu trigger 
  $('.menu-trigger').click(function() {
    $('.menu-bottom').slideToggle()
    $(this).toggleClass('active')
  })

  // desktop categories
  $('.categories button').click(function() {
    $(this).next().toggleClass('active')
    $(this).toggleClass('active')
  })

  $(document).on('click', function(event) {
    if (!$(event.target).closest('.categories').length) {
      $('.categories .wrapper, .categories button').removeClass('active')
    }
  });

  //product thumb images slider
  const swiper = new Swiper('.slider-product-images', {
    slidesPerView: 3,
    spaceBetween: 20,
    navigation: {
      nextEl: '.slider-nav-next',
      prevEl: '.slider-nav-prev',
    },
    breakpoints: {
      1312: {
        slidesPerView: 4,
        spaceBetween: 15,
        direction: 'vertical',
      }
    }
  });

  //product featured image slider
  const swiper2 = new Swiper('.slider-featured-image', {
    slidesPerView: 1,
    thumbs: {
      swiper: swiper
    }
  });

  // related slider
  const swiper3 = new Swiper('.slider-related', {
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      548: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      840: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1120: {
        slidesPerView: 4,
        spaceBetween: 40,
      }
    }
  });

  // product options
  $('.product-option').click(function() {
    const priceDisplay = $(this).attr('data-price')
    const priceCrossed = $(this).attr('data-price-crossed')
    $('.product-option').removeClass('active')
    $(this).addClass('active')
    $('.price .display')[0].innerHTML = priceDisplay
    $('.price .crossed')[0].innerHTML = priceCrossed
  })

  //counter
  let counterDisplay = $('.qty')[0];
  let counterVal = 1

  // counter add
  $('.counter-add').click(function() {
    counterVal += 1
    counterDisplay.innerHTML = counterVal.toLocaleString()
  });

  // counter minus
  $('.counter-minus').click(function() {
    if (counterVal > 1) {
      counterVal -= 1
      counterDisplay.innerHTML = counterVal.toLocaleString()
    }
  });

  // tabs
  $('.tab-buttons button').click(function() {
    const targetTab = $(this).attr('data-tab-name')
    $('.tab-buttons button, .tab-content').removeClass('active')
    $(this).addClass('active')
    $(`#${targetTab}`).addClass('active')
  });

  // fav
  $('.fav').click(function() {
    $(this).toggleClass('active')
  });

  //add class on scroll
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) { // Adjust the scroll position as needed
      $('.details-footer').addClass('active');
    } else {
      $('.details-footer').removeClass('active');
    }
  });

  // form submit
  $('#newsletter').submit(function(e) {
    e.preventDefault()
    $(this).addClass('submitted')
    $(this).find('button').text('Subscribed!')
    $(this).find('input').val('joedoe@gmail.com')
  });
})